<article class="markdown-body">
    <h1>SSW CodeAuditor</h1>
    <h3>What is CodeAuditor?</h3>
    <p>
      CodeAuditor is a tool that automatically scans your website and its code to check 
    </p>
    <ul class="list-disc">
      <li>Find broken Links - Links to pages which do not work</li>
      <li>Check HTML formatting - May cause pages to be incorrectly shown to the user</li>
      <li>Lighthouse scan - Audits for performance, accessibility, SEO, and more</li>
      <li>Artillery load test - See how website behaves when lot of users access it simultaneously</li>
    </ul> 
  </article>
  <article class="markdown-body mt-5">
    <h3>How does it work?</h3>
    <p>
      CodeAuditor runs scans and checks for issues on your website, and can then generate a report which can be viewed online.
    </p>
    <p>
      CodeAuditor is simple to use and can be either be run manually, or embedded directly into your build pipeline where it can be configured to automatically fail a build based on a number of broken links, SEO issues or other rules failures to ensure quality.
    </p>
    <p>Signing up for free and logging in to CodeAuditor will allow you to view and track your website's changes and improvements over time.</p>
  </article>
  <article class="markdown-body mt-5">
    <h3>What are the benefits?</h3>
    <p>
      CodeAuditor will automatically pick up and report issues which may exist in your website during the build process which enables you to catch any issues and fix them before they are published and cause bigger problems.
    </p>
  </article>
  <article class="markdown-body mt-5">
    <h3>What are the limitations?</h3>
    <ul class="list-disc">
      <li>CodeAuditor looks at the HTML browsers see, not the code developers write</li>
      <li>Only works on static sites, not Angular SPA, React SPA or Blazor</li>
      <li>To make it clear, this is not completely fixable, but we could improve it to make it less painful.</li>
    </ul> 
  </article>
  <article class="markdown-body mt-5">
    <h3>Upcoming features</h3>
    <ul class="list-disc">
      <li>Allow the integration of other tools like Resharper and SonarCube</li>
      <li>Check out our <a href="https://github.com/sswconsulting/ssw.codeauditor/issues">backlog</li>
    </ul> 
  </article>